<template>
	<header class="flex items-center text-white w-full">
		<div class="container mx-auto px-5 md:px-8 text-center md:text-right leading-relaxed tracking-wide">
			<h1 class="w-3/4  md:w-1/2 mx-auto md:mx-0  text-3xl lg:text-4xl font-medium md:font-bold">
				شركة زين للخدمات التموين والنظافة والسفرجة
			</h1>
			<p class="w-full md:w-1/2 text-md md:text-lg font-light md:font-medium my-5">
				خدمات شركة زين لا تشمل فقط خدمات التموين الغذائي للمؤسسات والمنظمات و مواقع العمل ، ولكن أيضا تشمل
				التنظيف والغسيل و الصيانة وتوفير الطاقة البشرية.
			</p>
			<router-link
				:to="{ name: 'About' }"
				class="bg-primary transform text-md transition-all duration-500 hover:scale-95 px-5 py-3  mt-3 inline-block rounded-lg"
				>المزيد عن الشركة</router-link
			>
		</div>
	</header>
</template>
<style lang="scss" scoped>
header {
	height: 90vh;
	background: url(../../assets/Hero.svg), url(../../assets/pexels-rene-asmussen-2922312.png);
	background-repeat: no-repeat;
	background-size: auto , cover;
	background-position: left -30px bottom -30px, center;
	// @media screen and(max-width: 768px) {
	// 	//background-origin: content-box;
	// 	background-size: cover;
	// }
	box-shadow: inset 0px 0px 0px 2000px rgba($color: #000000, $alpha: 0.5);
	overflow: hidden;
}
</style>
